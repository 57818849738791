import { metaTag } from "./Data";

const MetaTag = ({pageAlias, prefixTitle='', replacedDescription='', extraKeywords='', extraCanonical=''}) => {

  const metaTagKey = metaTag[pageAlias]
  const metaDescription = (replacedDescription != '') ? replacedDescription : metaTagKey.description
  return {
    title: prefixTitle + metaTagKey.title + ' - ' + metaTag['commonTitle'],
    description: metaDescription,
    canonical: `https://www.drcredithk.com/${extraCanonical}`,
    meta: {
      title: prefixTitle + metaTagKey.title + ' - ' + metaTag['commonTitle'],
      name: {
        keywords: extraKeywords + metaTagKey.keywords + metaTag['commonKeywords']
      }
    }
  };
};

export default MetaTag;