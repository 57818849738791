import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { getCategories } from '../services';

export default function Categories() {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories().then((newCategories) => {
      setCategories(newCategories);
    });
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 pb-12 mb-8">
      <h3 className="text-base mb-8 font-semibold border-b pb-2">分類</h3>
      {categories.map((category, index) => (
        <Link key={index} to={`/blog/${category.title}`}>
          <span className={`cursor-pointer block ${(index === categories.length - 1) ? 'border-b-0' : 'border-b'} pb-3 mb-3`}>{category.title}</span>
        </Link>
      ))}
    </div>
  )
}
