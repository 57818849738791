import BlockContent from "@sanity/block-content-to-react"
import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { getPostDetail } from '../services';
import Categories from '../components/Categories';
import PostWidget from '../components/PostWidget';
import Breadcrumb from '../components/Breadcrumb';
import AdsContainer from '../components/AdsContainer';
import DocumentMeta from 'react-document-meta';
import MetaTag from "../components/MetaTag";
export default function BlogDetail() {

  const [postDetail, setPostDetail] = useState([])
  const [postDetailMenu, setPostDetailMenu] = useState([])
  const [articleTitle, setArticleTitle] = useState('')
  const [description, setArticleDescription] = useState('')
  const [categoryTitle, setCategoryTitle] = useState('index')
  const [categoryAlias, setCategoryAlias] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { slug } = useParams()

  var menu = []
  let numberOfH2 = 0

  const serializers = {
    types: {
      block(props) {

        switch (props.node.style) {
          case 'h1':
            return <h1 id={props.children} className="text-2xl font-extrabold my-6">{props.children}</h1>
          case 'h2':
            numberOfH2++
            if (numberOfH2 % 2 == 0 && numberOfH2 != 0) {
              return <><AdsContainer adsSlotID='9212170330' adsLayout='' adsFormat='auto' /><h2 id={props.children} className="scroll-mt-24 text-xl font-bold text-blue-900 ">{props.children}</h2></>
            } else {
              return <h2 id={props.children} className="scroll-mt-24 text-xl font-bold text-blue-900 ">{props.children}</h2>
            }
          default:
            if (props.children == '') {
              return false
            }
            return <p className="text-base text-black leading-loose">{props.children}<br /></p>
        }
      },
    }
  }

  useEffect(() => {
    getPostDetail(slug).then((newPost) => {
      setPostDetail(newPost[0]);
      setCategoryTitle(newPost[0].categories[0].title);
      setCategoryAlias(newPost[0].categories[0].title);
      setArticleTitle(newPost[0].title)
      setArticleDescription(newPost[0].body[0].children[0].text)

      const postBody = newPost[0].body
      {
        postBody.map((item, index) => (
          item.style == 'h2' ? menu[index] = item.children[0].text : ''
        ))
      }
      setPostDetailMenu(menu)
      setIsLoading(false)
    });
  }, []);

  const level1 = { name: '計劃一覽', href: '/blog', }
  const level2 = { name: categoryTitle, href: "/blog/" + categoryAlias }

  const pageAlias = 'blogdetail'
  const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `/${categoryAlias}/${categoryTitle}` });
  return (
    <DocumentMeta {...meta}>

      <div className="px-4 mx-auto w-full container flex">

        {/*<Sidebar menu={postDetailMenu} />*/}
        <main className="flex-auto w-full min-w-0 lg:static lg:max-h-full lg:overflow-visible">
          <div className="block lg:flex w-full">
            <div className="flex-auto pt-6 min-w-0  lg:px-8 lg:pt-10 pb:12 xl:pb-24 lg:pb-16">
              {isLoading ? (
                <h1 className="uppercase font-bold text-3xl tracking-wide mb-5 md:text-6xl lg:text-4xl flex items-center mt-16 justify-center">
                  載入文章中...
                </h1>
              ) : (
                <div>

                  <div className="block md:flex md:justify-between">
                    <Breadcrumb level1={level1} level2={level2} />
                  </div>
                  <div className="pb-2 mb-4 border-b border-gray-200 dark:border-gray-800">
                    <h1 className="inline-block text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white" id="content">{postDetail.title}</h1>
                  </div>
                  <div className="">
                    {postDetail.mainImage && postDetail.mainImage.asset && (
                      <img
                        src={postDetail.mainImage.asset.url}
                        alt={postDetail.title}
                        title={postDetail.title}
                        className="my-2"
                      />
                    )}
                    <BlockContent
                      blocks={postDetail.body}
                      projectId="r3qizj17"
                      dataset="production"
                      serializers={serializers}
                      className="article-content break-words leading-10 text-lg"
                    />
                  </div>
                </div>

              )}
            </div>
            <div className="ml-0 lg:ml-4 flex-none z-20 pl-0 w-full lg:w-80 xl:text-sm">
              <div className="lg:sticky relative top-0 md:top-32">
                <AdsContainer adsSlotID='9212170330' adsLayout='' adsFormat='auto' />
                <PostWidget />
                <Categories />
              </div>
            </div>

          </div>
        </main>
      </div>
    </DocumentMeta>
  )
}
