import {
    WhatsappIcon
} from "react-share";
import { commonInfo } from '../components/Data'

export default function SocialMediaShare({size}) {
    const icons = [
        /*
        {
            landing: 'https://www.facebook.com/drcredithk',
            icon: FacebookIcon,
        },
        {
            landing: 'mailto:' + commonInfo.email,
            icon:EmailIcon,
        },
        */
        {
            landing: "https://api.whatsapp.com/send/?phone=" + commonInfo.phone + "&text&type=phone_number&app_absent=0",
            icon: WhatsappIcon,
            text: '',
        }

    ]
    const iconSize = size
    return (
        <div className="mx-1 flex">
            {icons.map((item, index) => (
                <a key={index} href={item.landing} target="_blank" rel="noreferrer" aria-label='drcredithk清數博士Wtsapp'>
                    <div className="text-sm mr-2">
                        {(item.text) ? <span>{item.text}</span> : <span></span>}
                        <item.icon className="mx-1 " size={iconSize} round={true} />
                    </div>

                </a>
            ))}
        </div>
    )
}
