import AdSense from 'react-adsense';

export default function AdsContainer({ maxWidth, minHeight, adsSlotID, adsLayout, adsFormat }) {
  return (
    <div className='max-h-[250px] text-center'>
      <AdSense.Google
                client='ca-pub-8965678723704510'
                slot={adsSlotID}
                style={{display: 'block',width:'100%'}}
                layout={adsLayout}
                format= {adsFormat} 
                responsive= 'true'
            />
    </div>
  );
}