import React, { useState } from 'react'
import { Link } from "react-router-dom"
import logo from '../images/logo.png'
import SocialMediaShare from './SocialMediaShare'
import { commonInfo } from '../components/Data'

export default function Header() {
    const [isOpen, setIsOpen] = useState(false)
    const [mobileCollapse, setMobileCollapse] = useState(false)
    const initMobileMenu = () => {
        if (window.innerWidth > 767) {
            setIsOpen(false)
        }
    }
    window.addEventListener("resize", initMobileMenu)
    return (

        <header className='z-[9999] sticky left-0 top-0'>
            <nav className=' w-full bg-[#fff] shadow-[0_4px_2px_-2px_rgba(149,157,165,0.2)] h-24 md:h-auto'>
                <div className='section-container'>
                    <div className='flex justify-between items-center'>
                        <div className='flex items-center'>
                            <Link to="/">
                                <div className='max-w-[170px] lg:max-w-[250px] -pb-2 flex ml-4'>

                                    <img className='w-24 md:w-32 -mb-2' src={logo} alt="logo"></img>
                                </div>
                            </Link>

                            <div className='ml-[30px] lg:ml-[50px] hidden md:block'>
                                <ul className='flex '>
                                    <li>
                                        <a target="_blank" href="/blog/清數計劃" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent font-bold hover:border-blue'>清數計劃</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="/blog/開戶優惠" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent font-bold hover:border-blue'>開戶優惠</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="/blog/信用卡優惠" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent font-bold hover:border-blue'>信用卡優惠</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="/blog/定存派息" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent font-bold hover:border-blue'>定存派息</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div></div>
                        <div className=' hidden md:block'>
                            <div className='flex'>
                                <SocialMediaShare size={48} />
                                <div className='grid'>
                                    <span className='text-sm my-auto'>
                                        Whatsapp <a href={`https://api.whatsapp.com/send/?phone=${commonInfo.phone}&text&type=phone_number&app_absent=0`}>{commonInfo.phone}</a>

                                    </span>

                                </div>

                            </div>

                        </div>

                        <div className='flex items-center mr-4'>
                            <div className='block cursor-pointer ml-[20px] md:hidden' onClick={() => setIsOpen(!isOpen)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>


                        </div>
                    </div>
                </div>
            </nav>
            <div className={`fixed top-24 w-full  bg-white ${isOpen ? "block h-full" : "hidden"}`}>
                <div className=''>


                    <ul className='mt-[20px]'>
                        <li>
                            <a target="_blank" rel="noreferrer" href="/blog/清數計劃" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>清數計劃</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="/blog/開戶優惠" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>開戶優惠</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="/blog/信用卡優惠" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>信用卡優惠</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="/blog/定存派息" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>定存派息</a>
                        </li>                    </ul>
                    <hr className='w-full border-[#e1e1e1]' />
                    <a href={`https://api.whatsapp.com/send/?phone=${commonInfo.phone}&text&type=phone_number&app_absent=0`} className='bg-[#25d366] text-[#fff] rounded-[3px] px-[20px] py-[12px] border-[1.5px] border-[#25d366] font-bold block mx-[20px] mt-[15px] text-center'>聯絡我們 Whatsapp {commonInfo.phone} </a>

                </div>
            </div>
        </header>



    )
}
