
import case1 from '../images/logo_footer.webp'
import case2 from '../images/logo_footer.webp'
import case3 from '../images/logo_footer.webp'

export const commonInfo =
{
  phone: "85297349700",
  phone2: "85297349700",
  email: "drcredithk@gmail.com"
}

export const DigitalMarketingSwiperList = [
  {
    arrayKey: 1,
    thumbnail: case1,
    topHeading: "陳先生，職業 銀行經理 (15年)，每月收入約$110,000。陳先生目前欠款共250萬，包括信用卡債務和個人貸款。由於陳先生的工作穩定且有高收入，他曾多次嘗試申請貸款來償還債務，但因為過去的信用紀錄不佳而被各貸款機構拒絕。陳先生感到非常煩惱，急需找到解決債務問題的方法。",
    bottomHeading: "後來，陳先生得知了drcredithk，並尋求我們的協助來處理他的債務。我們通過大額清償和集中清償的方式，成功減輕了陳先生的供款壓力。由於他的工作並未受到影響，他可以繼續保持穩定的收入來還款。透過我們的幫助，陳先生成功解決了債務問題，並重新建立了良好的信用紀錄。"

  },
  {
    arrayKey: 2,
    thumbnail: case2,
    topHeading: "蔡先生，每月收入約$35,000。蔡先生目前欠款共99萬，包括信用卡債務、銀行貸款和二線財務。由於蔡先生的工作需要與客戶長期應酬，且經常使用信用卡付款，因此累積了大量高息的信用卡債務。他曾多次嘗試自行償還債務，包括借貸款來還清信用卡債務，但未能成功。",
    bottomHeading: "後來，蔡先生尋求了drcredithk的二線清數服務。在短短十數天的時間內，我們成功為蔡先生處理了他的申請。透過我們的專業服務，蔡先生成功解決了他的債務問題，並得到了一個更可負擔的償還計劃。現在，他可以重建自己的財務狀況，並更有效地管理自己的財務。"
  },
  {
    arrayKey: 3,
    thumbnail: case3,
    topHeading: "李小姐是一名專業會計師，每月收入8萬港幣。她的信用紀錄顯示總外債超過150萬港幣，每月供款高達6-7萬港幣。此外，她還需要負擔一家四口的生活開支，因此生活壓力非常大，每天都無法入睡，擔心債務問題。",
    bottomHeading: "透過我們的支援，李小姐可以獲得更具彈性的償還計劃，減輕她的負擔，並提供她所需的資金來應對生活開支。這使得她能夠放心地專注於工作，同時擁有更好的睡眠品質。我們的目標是幫助客戶度過困難時期，重新建立穩定的財務狀況。"
  }


];
export const formQuestions = [
  {
    htmlTag: "input",
    rows: "",
    type: "text",
    placeholder: "稱呼 Name",
    name: "form-name",
    validations: { required: true },
    errorMessages: { required: "請填寫此欄" }
  },
  {
    htmlTag: "input",
    rows: "",
    type: "tel",
    placeholder: "聯絡電話 Phone Number",
    name: "form-phone",
    validations: { required: true, pattern: /[4-6|9][0-9]{7}/ },
    errorMessages: { required: "請填寫此欄", pattern: "請輸入正確的電話號碼 (只需8個數字)" },
  },
  {
    htmlTag: "textarea",
    rows: "6",
    type: "message",
    placeholder: "留言 Your Message",
    name: "form-message",
    validations: { required: true, maxLength: 200 },
    errorMessages: { required: "請填寫此欄", maxLength: "字數上限為200字" },
  }
]
export const metaTag = {
  commonTitle: '香港清數 - 清數博士 drcredithk.com',
  commonKeywords: '財務清數,破產,按揭,借貸,清數易批,私人貸款免tu,清數專家',
  home: {
    title: '財經資訊',
    description: "drcredithk 清數博士成立於2012年是一間擁有超過10年以上經驗的專業貸款團隊，職員全部曾任職銀行及財務行業。過往成功處理數千宗減輕供款個案，幫助不同行業，不同財務問題的客人順利減輕供款壓力，減少客人多次申請，多次遞交文件仍然不成功的申請問題。",
    canonical: "https://www.drcredithk.com/",
    keywords: "二線清數,二線攬數,大額清數"

  },
  blog: {
    title: '財經資訊',
    description: "drcredithk 清數博士成立於2012年是一間擁有超過10年以上經驗的專業貸款團隊，職員全部曾任職銀行及財務行業。過往成功處理數千宗減輕供款個案，幫助不同行業，不同財務問題的客人順利減輕供款壓力，減少客人多次申請，多次遞交文件仍然不成功的申請問題。",
    canonical: "https://www.drcredithk.com/blog",
    keywords: "二線清數,二線攬數,大額清數"
  },
  blogdetail: {
    title: '財經資訊',
    description: "drcredithk 清數博士成立於2012年是一間擁有超過10年以上經驗的專業貸款團隊，職員全部曾任職銀行及財務行業。過往成功處理數千宗減輕供款個案，幫助不同行業，不同財務問題的客人順利減輕供款壓力，減少客人多次申請，多次遞交文件仍然不成功的申請問題。",
    canonical: "https://www.drcredithk.com/blog",
    keywords: "二線清數,二線攬數,大額清數"
  },
  error: {
    title: '頁面不存在',
    description: "這是404頁面。 您已被重定向至此處，因為您請求的頁面不存在！"
  }
}
