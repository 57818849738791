import PostCard from '../components/PostCard';
import { useState, useEffect } from "react"
import { getPosts } from '../services';
import { useParams } from 'react-router-dom';
import AdsContainer from "../components/AdsContainer";
import Breadcrumb from "../components/Breadcrumb";
import DocumentMeta from 'react-document-meta';
import MetaTag from "../components/MetaTag";
export default function Blog() {
  const [posts, setPosts] = useState([])
  const [alias, setAlias] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const { category } = useParams()

  useEffect(() => {
    getPosts(category).then((newPosts) => {
      setPosts(newPosts);
      setAlias(newPosts[0].categories.title);
      setDescription(newPosts[0].categories.description);
      setTitle(newPosts[0].categories.title);
    });

  }, [category]);
  const level1 = { name: '計劃一覽', href: '/blog', }
  const level2 = { name: category, href: `/blog/${category}`, }

  const pageAlias = 'blog'
  const meta = MetaTag({ pageAlias: pageAlias, extraCanonical: `/${category}` });
  return (
    <DocumentMeta {...meta}>
      <div className="relative z-20 dark:bg-gray-900 pt-4 max-w-7xl mx-auto px-4 sm:px-6 min-h-[480px] md:min-h-[680px]">
        <div className="block md:flex md:justify-between">
          <Breadcrumb level1={level1} level2={level2}/>
        </div>
        <div className="flex flex-wrap mx-4">
          {posts.map((post, index) => (
            <>
              {index % 4 === 0 && index !== 0 ?  <div className="w-full md:w-1/2 lg:w-1/3 px-4">
              <div className="w-full max-w-[370px] mx-auto mb-10"><AdsContainer adsSlotID='9212170330' adsLayout='' adsFormat='auto' /> </div></div>: null}
              <PostCard key={index} post={post} />
            </>
          ))}
        </div>
      </div>
    </DocumentMeta>
  )
}
